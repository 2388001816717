<template>
    <div id="pageTable">
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <simple-table
                        :tableName="'Menú'"
                        :classes="tableClasses"
                        :rows="menuItems"
                        :columns="headersMenu"
                        :perPage="[100]"
                        :showPagination="false"
                        >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="index" v-for="(item, index) in data">
                                <td>
                                    <div>{{item.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        @click="openModalMenu(item)"
                                                        small
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">list</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Privilegios</span>
                                            </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                </v-col>
            </v-row>
        </v-container>

        <v-row>
            <v-dialog
                v-model="dialogTree"
                persistent
                max-width="600px"
                style="max-height: 800px;"
                scrollable
            >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnmodalTree"></v-btn>
                </template>
                <perfect-scrollbar style="background:#FFF">
                    <v-card elevation="0">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                            <img :src="imagen" class="imgModal" />
                            <h2 class="titleModal">{{ titulo_modal }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="justify-center card_texto">
                            <div id="loadingModal" v-show="loading">
                                <div id="loading">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="blue"
                                    ></v-progress-circular>
                                </div>
                            </div>
                            <v-container grid-list-md id="contenedor" v-show="!loading">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12">
                                        <draggable 
                                            v-model="menuConfig.menuUsuario" 
                                            :options="{ group: { name: 'gf1', put: 'gf1'}, animation: 300 } " >
                                        <template v-for="(item, index) in menuConfig.menuUsuario">
                                            <template v-if="true/*item.sistema == 'nomina'*/">
                                                <v-layout
                                                    :key="item.id"
                                                    row
                                                    v-if="item.children.length == 0"
                                                >
                                                    <div
                                                        style="position: relative; width: 100px; height: 60px;"
                                                    >
                                                        <input
                                                            :id="'chkP' + item.id"
                                                            type="checkbox"
                                                            class="ck-checkbox"
                                                            v-model="item.active"
                                                            :disabled="menuConfig.id == 1"
                                                        />
                                                        <label :for="'chkP' + item.id" class="ck-label">
                                                            {{ item.name }}
                                                        </label>
                                                    </div>
                                                </v-layout>

                                                <v-layout row :key="item.id" v-else>
                                                    <div
                                                        style="position: relative; width: 100px; height: 55px;"
                                                    >
                                                        <input
                                                            :id="'chkP' + item.id"
                                                            type="checkbox"
                                                            class="ck-checkbox"
                                                            @click="_selectedAll(item, item.type, index)"
                                                            v-model="item.active"
                                                            :disabled="menuConfig.id == 1"
                                                        />
                                                        <label class="ck-label" :for="'chkP' + item.id">
                                                            {{ item.name }}
                                                        </label>
                                                    </div>

                                                    <div class="checkbox-container" style="margin-top: 40px;">
                                                        <draggable 
                                                        v-model="item.children" 
                                                        :options="{ group: { name: 'gc' + item.id, put: 'gc' + item.id}, animation: 300 } " >
                                                        <template v-for="child in item.children">
                                                        <div
                                                            :key="child.id"
                                                            style="position: relative; width: 100px; height: 40px; "
                                                        >
                                                            <input
                                                                :id="'chkC' + child.id"
                                                                class="ck-checkbox"
                                                                type="checkbox"
                                                                @click="
                                                                    _selectedAll(item, child.type, index, child)
                                                                "
                                                                v-model="child.active"
                                                                :disabled="menuConfig.id == 1"
                                                            />
                                                            <label :for="'chkC' + child.id" class="ck-label">
                                                                {{ child.name }}
                                                            </label>
                                                        </div>
                                                        </template>
                                                        </draggable>
                                                    </div>
                                                </v-layout>
                                            </template>
                                        </template>
                                        </draggable>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="updateMenu()" 
                                :loading="loading_guardar"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

//IMPORTS

//Vuex
import { mapState } from "vuex";
//Componentes
import Datatable from "@/components/simpletable/Datatable.vue";
import PerfectScrollbar from "perfect-scrollbar";
import Notify from "@/plugins/notify";
import draggable from "vuedraggable";
//Apis
import apiMenu from "../api/apiMenu";

export default {
    components: {
        'simple-table': Datatable,
        draggable
    },
    data() {
        return {
            loading_guardar     : false,
            loading             : true,
            dialogTree          : false,
            datosLogin          : "",
            imagen              : "",
            titulo_modal        : "",
            rol                 : "",
            menuConfig: {
                id  : null,
                menuUsuario : [],
            },
            menuItems : [],
            tableClasses: {
                "t-head" : 
                {
                    "tamanio-head":true
                }
            },
            headersMenu: [
                {   
                    label: 'Rol', 
                    name: 'rol', 
                    filterable: false
                },
                {   
                    label: 'Opciones', 
                    name: 'opciones', 
                    align:'center',
                    filterable: false
                },
            ],
        }    
    },
    methods: {

        //FUNCIONES GENERALES

        /*
        Entrada: data: objeto del menu
        Salida:
        Descripción: Recibe el obeto del menu para la 
                     carga y visibilidad del modal.
        */
        openModalMenu(data) {
            this.menuConfig.id = data.id;
            this.imagen = "/static/bg/menu.png";
            this.titulo_modal = "Configurar menú";
            this.loading = true;

            this.menuConfig.menuUsuario = this.createMenuData(
                this.menu,
                data.vistas
            );

            this.loading = false;
                
            document.getElementById("btnmodalTree").click();
        },
        /*
        Entrada:
        Salida:
        Descripción: Regresa a false los boloeanos utilizados 
                     en el modal de privilegiios para la 
                     desvancido del modal.
        */
        cerrarModal() {
            this.dialogTree = false;
            this.loading_guardar = false;
        },
        /*
        Entrada: err: array de cadenas de excepciones que regresa el servidor despues de una peticion fallida 
        Salida:
        Descripción: Muestra las exepciones recividas 
                     desde el servidor por medio de un toast.
        */
        respuesta_error(err) {
            this.loading_guardar = false;
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        },
        /*
        Entrada:
            data: objeto del menu,
            type: cadena bandera para saber si es un hijo o un padre,
            index: entero que indica la pocision en el menuConfig.menuUsuario,
            child: boleano enviado para saber si es un hijo de un elemento padre
        Salida:
        Descripción: Recibe de 3 a 4 parametos para saber si 
                     agregar o quitar todos los hijos de un 
                     padre y de manera individual con los hijos 
                     del menu (menuConfig.menuUsuario).
        */
        _selectedAll(data, type, index, child) {
            if (type == "M") {
                let newValue = !data.active ? true : false;

                for (let i = 0; i < data.children.length; i++) {
                    let item = data.children[i];
                    item.active = newValue;
                    data.children.splice(i, 1, item);
                }
            } else {
                if (!child.active) {
                    data.active = !false;
                    this.menuConfig.menuUsuario.splice(index, 1, data);
                    return;
                }

                let arrayFilter = data.children.filter(
                    (element) => element.active == false
                );
                if (arrayFilter.length + 1 == data.children.length) {
                    data.active = false;
                    this.menuConfig.menuUsuario.splice(index, 1, data);
                }
            }
        },
        /*
        Entrada:
        Salida:
        Descripción: Crea un scroll-bar con estilo de manera 
                     dinamica para el tamaño del modal.
        */
        initScroll() {
            const elementScroll = document.querySelector("#scroll");
            if (elementScroll) {
                const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollY: true,
                });
            }
            $(".ps__thumb-x").css("position", "fixed");
        },
        
        //FUNCIONES ESPECIFICAS

        /*
        Entrada:
        Salida:
        Descripción: Realiza la peticion apiMenu.find que 
                     regresa los datos para cargar la tabla Menú.
        */
        getMenus() {
            apiMenu
                .find()
                .then((response) => {
                    this.menuItems = response.data;
                    //console.log(this.menuItems);
                })
                .catch((err) => {
                    console.error(err);
                    this.loading_guardar = false;
                    Notify.ErrorToast("Ocurrió un error al cargar los menus.");
                });
        },
        /*
        Entrada: arrayParent : array del menu obtenido de Vuex auth
                 arrayRol : array de menus del rol seleccionado
        Salida: newArray : un array de objetos {
                                            id: null,
                                            name: null,
                                            valor: null,
                                            type: null,
                                            active: false,
                                            children: arrayChild,
                                        } 
                            manipuado para el pintado en el modal.
        Descripción: Recibe el menu de session y el menu que contiene 
                     el rol para indicar que menus del menu de sesion 
                    estan seleccinados para que sean mostrados en el modal.
        */
        createMenuData(arrayParent, arrayRol) {
            var newArray = [];
            
            if(arrayRol){
                arrayRol = JSON.parse(arrayRol);
            }else{
                arrayRol = [];
            }
        
            for(let parent of arrayParent){

                let parentActive = false;

                let arrayChild = [];
                let childActive = false;

                let arrayParentFilter = arrayRol.filter(
                (element) => element.vista_id == parent.id
                );

                if (arrayParentFilter.length > 0) {
                    parentActive = true;
                }
                
                if(parent.hijos){
                    for(let child of parent.hijos){
                        childActive = false;
                        let arrayChildFilter = arrayRol.filter(
                        (element) => element.vista_id == child.id
                        );

                        if (arrayChildFilter.length > 0) {
                            childActive = true;
                        }

                        arrayChild.push({
                            id: child.id,
                            name: child.nombre,
                            valor: child.valor,
                            type: child.tipo,
                            active: childActive,
                        });
                    }
                }

                newArray.push({
                    id: parent.id,
                    name: parent.nombre,
                    valor: parent.valor,
                    type: parent.tipo,
                    active: parentActive,
                    children: arrayChild,
                });
            }

            return newArray;
        },
        /*
        Entrada:
        Salida:
        Descripción: Realiza la peticion apiMenu.updateMenu 
                     para editar las vistas relacionadas a 
                     un rol y recarga la tabla.
        */
        updateMenu() {
            this.loading_guardar = true;
            var params = {
                id: this.menuConfig.id,
                vistas: [],
            };

            //Crea un nuevo array de objetos para el campo params.vistas como para luego parsearse en JSON
            for (var i = 0; i < this.menuConfig.menuUsuario.length; i++) {
                let itemParent = this.menuConfig.menuUsuario[i];
                if (itemParent.active) {
                params.vistas.push({ vista_id: itemParent.id });

                if (itemParent.children) {
                    for (var j = 0; j < itemParent.children.length; j++) {
                    let itemChild = itemParent.children[j];
                    if (itemChild.active) {
                        params.vistas.push({ vista_id: itemChild.id });
                    }
                    }
                }
                }
            }

            params.vistas = JSON.stringify(params.vistas);
            //console.log(params);
            apiMenu
                .updateMenu(params)
                .then(async (response) => {
                    this.cerrarModal("cerrar");
                    this.getMenus();
                    Notify.Success("Menú de usuario", response.message);
                    
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
        }
    },
    //COMPUTED
    /*
    Entrada:
    Salida:
    Descripción: Se importan la variable menu (contiene el menu 
                 obtenido en el logueo) del VUEX mapState de auth
                 para su uso en el modulo.
    */
    computed: {
        ...mapState("auth", ["menu"]),
    },
    //CREATED
    /*
    Entrada:
    Salida:
    Descripción: Se recopila el rol del usaurio logeado 
                 en el sistema por medio de variables de sesion,
                 para verificar que tenga acceso al modulo.
    */
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if (this.rol == "admin-sucursal" || this.rol == "admin-depto" || this.rol == "empleado") {
            this.$router.push("/dashboard");
            Notify.Warning("¡Denegado!","Tu Rol no permite el acceso a esta vista.");
        }
    },
    //MOUNTED
    /*
    Entrada:
    Salida:
    Descripción: Se ejecuta 2 funciones principales 
                 para el la iniciacion del modulo.
    */
    mounted() {
        this.initScroll();
        this.getMenus();
    },
}
</script>

<style scoped>
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .botonModal {
        display: none !important;
    }
</style>


