import Vue from 'vue';

export default {

    /*
    Entrada:
    Salida: response: array de objetos rol que contienen vistas del menu.
    Descripción: Regresa los datos para el llenado la tabla.
    */
    find(parametros) {
        return Vue.axios.get("configuraciones/menu/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    /*
    Entrada: objeto {id:null,vistas:""}
    Salida: response: un mensaje de exito o excepcion
    Descripción: Edita el las vistas de un rol.
    */
    updateMenu(parametros){
        return Vue.axios.put("configuraciones/menu", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    
}